import "./App.css";
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import velorexMembersClubABI from "./contracts/velorexMembersClubABI.json";
import Lottie from "react-lottie-player";
import animationData from "./lotties/coin.json";
import Background from "./images/ATM-render-1.png";

//LIVE SWITCH
const Live = true;

//Config
const velorexMembersClubNFTAddress =
  "0x0Adc873e88Ec06066Cf1030c6Ff1482037Fa18c5";

//Application
function App() {
  // Connecting to Blockchain
  const [accounts, setAccounts] = useState([]);

  async function ConnectAccounts() {
    if (!window.ethereum) {
      alert("Metamask not installed");
    } else {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const contract = new ethers.Contract(
        velorexMembersClubNFTAddress,
        velorexMembersClubABI,
        signer
      );
      console.log("Connected to: " + accounts);
      setAccounts(accounts);
      setAddress(await signer.getAddress());
      const onList = await contract.whitelistWallets(accounts.toString());

      if (!onList) {
        setPrice(0.14);
      } else {
        setPrice(0.105);
      }
    }
  }

  useEffect(() => {
    ConnectAccounts();
  }, []);

  //Minting functionality
  const [mintAmount, setMintAmount] = useState(1);
  const [onSetPrice, setPrice] = useState("0");
  const choppedPrice = onSetPrice * mintAmount;
  const choppedToStr = choppedPrice.toString();
  const choppedParsed = choppedToStr.substring(0, 6);

  //Image List
  const [listImageNames, setListImageNames] = useState([]);

  //Setting wallet functionality
  const [address, setAddress] = useState("None");

  //Limit mint counter between values of 1 and 5 - reset values
  if (mintAmount <= 0) {
    setMintAmount(mintAmount + 1);
    alert("Mint amount must be greater than 0!");
  } else if (mintAmount >= 6) {
    setMintAmount(mintAmount - 1);
    alert(
      "If you want to mint more than 5 NFTs in this wallet, please contact support@velorex.net"
    );
  }

  async function revealNFT() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        velorexMembersClubNFTAddress,
        velorexMembersClubABI,
        signer
      );

      const supply = await contract.totalSupply();
      var nftArr = [];
      for (let i = 0; i < supply; i++) {
        let nftID = await contract.ownerOf(i);
        let nftIDstr = nftID.toString();
        let toString = accounts.toString();

        if (nftIDstr.toLowerCase() == toString.toLowerCase()) {
          console.log("You minted token number: " + (i + 1));
          nftArr.push(i);
        }
      }

      setListImageNames([...nftArr]);
    }
  }

  async function handleMint() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        velorexMembersClubNFTAddress,
        velorexMembersClubABI,
        signer
      );
      try {
        //Setting the current wallet address
        setAddress(await signer.getAddress());

        //Checking if wallet is in the whitelist
        const preBoolean = await contract.whitelistWallets(address);

        //If the wallet is in the whitelist - reduce the cost of minting by 25%
        if (preBoolean) {
          var checkedPrice = 0.105 * mintAmount + "";
        } else {
          var checkedPrice = 0.14 * mintAmount + "";
        }
        //Check if the wallet belongs to the owner and enable free minting
        const adminBoolean = await contract.adminRights(address);
        if (adminBoolean) {
          var checkedPrice = "0";
        }

        const response = await contract.mint(mintAmount, {
          value: ethers.utils.parseEther(checkedPrice),
        });
        console.log("response: ", response);
      } catch (err) {
        console.log("error: ", err);
        alert(
          "Error minting, please check you have enough balance for the cost + gas"
        );
      }
    }
  }

  ///Function inserting string, parsing to integer, saving result as intIndex, checking length of the integer
  const getFileName = (index) => {
    let res = "";
    let intIndex = parseInt(index);
    if (intIndex < 10) {
      res = "000" + intIndex;
    } else if (intIndex >= 10 && intIndex < 100) {
      res = "00" + intIndex;
    } else if (intIndex >= 100 && intIndex < 1000) {
      res = "0" + intIndex;
    } else {
      res = intIndex;
    }
    // concatenating with zeros and .png to match image file names.
    return res + ".png";
  };

  //Application
  if (!Live) {
    console.log("Minting paused");

    return (
      <div
        className="background"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <h2>Minting will be live at 8pm GMT today (sunday 13th)</h2>
        <div className="lottieAnim">
          <Lottie
            loop
            animationData={animationData}
            play
            style={{ width: 300, height: 300 }}
          />
        </div>
      </div>
    );
  } else
    return (
      <>
        <div
          className="background"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="connectedWallet">
            Connected: 🟢 {"0x.." + address.slice(-4)}
          </div>

          <div className="appText">Velorex Members Club NFT</div>

          <div className="lottieAnim">
            <Lottie
              loop
              animationData={animationData}
              play
              style={{ width: 250, height: 250 }}
            />
          </div>

          <h3>Price: {choppedParsed + " Eth"}</h3>

          <button className="mintButton" onClick={handleMint}>
            Mint {mintAmount} NFTs
          </button>
          <br></br>
          <br></br>
          <button
            className="selectorButton2"
            onClick={() => setMintAmount(mintAmount - 1)}
          >
            -
          </button>
          <button
            className="selectorButton"
            onClick={() => setMintAmount(mintAmount + 1)}
          >
            +
          </button>
          <br></br>
          <br></br>
          <br></br>
          <button className="mintButton" onClick={revealNFT}>
            Reveal NFTs
          </button>

          {listImageNames.map((imageName, index) => {
            return (
              <div key={index} style={{ padding: "0px" }}>
                <img
                  src={require(`./images/${getFileName(imageName)}`)}
                  alt=""
                  style={{
                    maxWidth: "90%",
                    maxHeight: "500px",
                    padding: "20px",
                  }}
                />
              </div>
            );
          })}
        </div>
      </>
    );
}

export default App;
